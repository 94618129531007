import React from "react";
import * as PropTypes from "prop-types";
import { Card, CardContent, CardActionArea, Typography, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link as GatsbyLink } from "gatsby";
import { AuthorOfPublication } from "./Author";
import { ROUTES, lightThemedLink } from "../navigation/InternalRoute";

const useStyles = makeStyles(theme => ({
  cardAction: {
    margin: "1rem 0",
    color: theme.palette.secondary.contrastText,
  },
  card: {
    display: "flex",
    backgroundColor: "#fff",
    color: "inherit",
    border: "solid #fff 1px",
    "&:hover": {
      border: `${theme.palette.primary.main} solid 1px`,
      transform: "translateY(-3px)",
    },
  },
  cardContent: {
    padding: "2rem",
    [theme.breakpoints.down("xs")]: {
      padding: "1rem 1rem",
    },
  },
  flex: {
    display: "flex",
  },
  grow: {
    grow: 1,
  },
  ...lightThemedLink,
}));

const BlogTeaser = ({ title, date, author, path, excerpt }) => {
  const classes = useStyles();

  return (
    <CardActionArea component={GatsbyLink}
                    to={`${ROUTES.BLOG.TO}/${path}`}
                    className={classes.cardAction}>
      <Card className={classes.card}>
        <div>
          <CardContent className={classes.cardContent}>
            <Link variant="h6" component="span" className={classes.link}>{title}</Link>
            <Typography color="inherit">{excerpt}</Typography>
            <AuthorOfPublication datePublished={date} author={author} />
          </CardContent>
        </div>
      </Card>
    </CardActionArea>
  );
};
BlogTeaser.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired,
};
export default BlogTeaser;