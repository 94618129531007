import React from "react";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import Layout from "../components/element/layout/Layout";
import PageBanner from "../components/element/page-element/PageBanner";
import LayoutSetBackground from "../components/element/layout/LayoutSetBackground";
import { ooTextWidth, grey8 } from "../components/element/theme";
import LayoutColumn from "../components/element/layout/LayoutColumn";
import NewsletterBanner from "../components/navigation/footer/NewsletterBanner";
import { ROUTES } from "../components/navigation/InternalRoute";
import BlogTeaser from "../components/blog/BlogTeaser";
import SEO from "../components/element/SEO";


const h1Heading = "Blog";
// const h1Subheading = "Development, video game discovery, and entrepreneurship";
// const h1Subheading = "Deciphering the discovery problem in video games";
const newsletterMessage = "Get notified when new posts are released";


const seo = {
  title: "Blog - Oberion",
  description: null,
};

const Blog = (props) => {
  const { data: { allMarkdownRemark: { edges } } } = props;

  return (
    <Layout>
      <SEO {...seo} />
      {/* --- Banner --- */}
      <PageBanner heading={h1Heading} />
      <NewsletterBanner path={ROUTES.BLOG.TO}
                        message={newsletterMessage}
                        padding="1.5rem 0.5rem"
                        extraButton />

      <LayoutSetBackground backgroundColor={grey8}>
        <LayoutColumn alignItems="flex-start"
                      justifyContent="center"
                      textAlign="left"
                      maxWidth={ooTextWidth}
                      paddingBottom="3rem">

          {/* --- Content --- */}
          {edges.map(({ node }) => {
            const nodeProps = {
              title: node.frontmatter.title,
              date: node.frontmatter.date,
              author: node.frontmatter.author,
              excerpt: node.excerpt,
              path: `${node.fields.slug}`,
            };
            return (
              <BlogTeaser key={node.id} {...nodeProps} />
            );
          })}

        </LayoutColumn>
      </LayoutSetBackground>
    </Layout>
  );
};

// noinspection JSUnusedGlobalSymbols
export const blogPageQuery = graphql`
query {
  allMarkdownRemark(filter: {
    fileAbsolutePath: {regex: "content/blog/"}}, 
    sort: {fields: [frontmatter___date], order: DESC}) 
  {
    edges {
      node {
        id
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY")
          author
        }
        excerpt
        fields {slug}
      }
    }
  }
}
`;
// excerpt(pruneLength: 500)

Blog.propTypes = {
  data: PropTypes.object.isRequired,
};
export default Blog;
